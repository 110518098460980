const data = {
    categories: [  
    ],
    items: [
        {
            highlight: true,
            category:'View Resource',
            image: {
                src: "./images/catalogo.png",
                alt: "catalogue"
            },
            title: 'General Catalog',
            description: '',
            file:"./files/CUCo_Catalog_GEN_EN001.pdf"
        },
        {
            highlight: false,
            category:'View Resource',
            image: {
                src: "./images/monofolha.png",
                alt: "image"
            },
            title: 'General Infosheet ',
            description: '',
            file:"./files/CUCo_Infosheet_GEN_EN001.pdf"
        },

        
    ],
    /*button : {
        text: 'See more',
        page: '/'
    }*/

}

export default data;